
import { defineComponent } from "vue";
import BaseAlinea from "../base/BaseAlinea.vue";
import BaseButton from "../base/BaseButton.vue";

export default defineComponent({
  components: { BaseButton, BaseAlinea },
  setup() {
    const onClick = () => {
      const url =
        "https://www.google.com/maps/search/?api=1&query=lindendreef+5+3137CJ+Vlaardingen+NL";
      window.open(url);
    };

    return { onClick };
  }
});
