<template>
  <div class="contact">
    <!-- ts-ignore -->
    <BaseHeroImg
      :imgSrc="require('@/assets/images/contact-hero.png')"
      imgAlt="vliegende volgels"
    />
    <BaseHero titleAlignment="left"> Contact </BaseHero>

    <ContactForm />

    <Route />
  </div>
</template>

<script lang="ts">
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import Route from "@/components/contact/Route.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: { BaseHero, BaseHeroImg, ContactForm, Route },
  setup() {
    const text = ref("adas");

    return { text };
  }
});
</script>

<style lang="scss" scoped>
.contact {
  position: relative;
  overflow-x: hidden;
}
</style>
