<template>
  <div class="contact-form">
    <h1 class="contact-form__header wrapper">Contact opnemen</h1>

    <form class="contact-form__form" @submit.prevent="onSubmit">
      <div class="contact-form__background"></div>

      <BaseInput
        class="contact-form__input wrapper"
        label="Je Naam"
        placeholder="Naam"
        v-model="name"
        :error="nameError"
      />
      <BaseInput
        class="contact-form__input wrapper"
        label="Je e-mail"
        placeholder="mail@voorbeeld.nl"
        type="email"
        v-model="email"
        :error="emailError"
      />

      <BaseInput
        label="Je bericht"
        :error="bodyError"
        v-model="body"
        class="contact-form__input wrapper"
        placeholder="Bericht"
        textArea
      />

      <div class="wrapper contact-form__submit-container">
        <BaseSpinner v-if="isLoading" class="spinner" />
        <input
          v-else
          type="submit"
          value="Verstuur"
          class="contact-form__submit"
        />
      </div>
    </form>

    <div class="contact-form__message-box wrapper">
      <p v-if="isSubmitted">Je bericht is verstuurd!</p>
      <p v-else-if="hasSubmitError" class="error">
        Er is iets mis gegaan, probeer het nog eens.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs } from "vue";
import BaseInput from "../base/BaseInput.vue";
import { FormContent } from "@/@types/FormContent";
import { FormErrors } from "@/@types/FormErrors";
import BaseSpinner from "../base/BaseSpinner.vue";
import axios from "axios";

const initialFormContent = {
  name: "",
  email: "",
  body: ""
};

export default defineComponent({
  components: { BaseInput, BaseSpinner },
  setup() {
    const isLoading = ref(false);
    const hasSubmitError = ref(false);
    const isSubmitted = ref(false);

    const formContent = reactive<FormContent>(initialFormContent);

    const formErrors = reactive<FormErrors>({
      nameError: "",
      emailError: "",
      bodyError: ""
    });

    const isValid = () => {
      let isValid = true;
      const { name, email, body } = formContent;

      if (name.length <= 0) {
        isValid = false;
        formErrors.nameError = "Dit veld is verplicht";
      }

      if (email.length <= 0) {
        isValid = false;
        formErrors.emailError = "Dit veld is verplicht";
      }

      if (body.length <= 0) {
        isValid = false;
        formErrors.bodyError = "Dit veld is verplicht";
      }

      return isValid;
    };

    const onSubmit = async () => {
      isLoading.value = true;
      isSubmitted.value = false;
      hasSubmitError.value = false;
      formErrors.bodyError = "";
      formErrors.emailError = "";
      formErrors.nameError = "";

      if (!isValid()) {
        isLoading.value = false;
        return;
      }

      try {
        await axios.post("/.netlify/functions/sendmail", formContent);
        isSubmitted.value = true;

        formContent.name = "";
        formContent.email = "";
        formContent.body = "";
      } catch {
        hasSubmitError.value = true;
      }

      isLoading.value = false;
    };

    return {
      onSubmit,
      ...toRefs(formContent),
      ...toRefs(formErrors),
      isLoading,
      hasSubmitError,
      isSubmitted
    };
  }
});
</script>

<style lang="scss" scoped>
@import "@/global";

.contact-form {
  &__header {
    margin-top: 31px;
    color: $primary1;
  }

  &__background {
    z-index: -1;
    background: #d4d1ce41;
    position: absolute;
    width: 100%;
    height: calc(100% - 42px - 18px);
    margin-top: 42px;
    margin-bottom: 10px;
  }

  &__form {
    position: relative;
    width: 100%;
  }

  &__input {
    margin-top: 21px;
  }

  &__submit-container {
    text-align: center;
  }

  .spinner {
    margin-top: 38px;
  }

  &__submit {
    margin-top: 43px;
    border: none;

    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 0 10px;
    min-width: 156px;
    height: 40px;
    background: $primary1;
    color: white;
  }

  &__message-box {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;

    .error {
      color: $primary1;
    }
  }
}
</style>
