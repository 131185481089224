
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import Route from "@/components/contact/Route.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: { BaseHero, BaseHeroImg, ContactForm, Route },
  setup() {
    const text = ref("adas");

    return { text };
  }
});
