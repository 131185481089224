
import { defineComponent } from "vue";

const props = {
  label: {
    type: String,
    required: true
  },
  modelValue: {
    type: String,
    required: false,
    default: ""
  },
  error: {
    type: String,
    required: false,
    default: ""
  },
  textArea: {
    type: Boolean,
    default: false
  }
};

export default defineComponent({
  props: props
});
