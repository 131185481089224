<template>
  <div class="input">
    <div class="input__row">
      <label for="input">{{ label }}</label>
      <p class="input__error" v-if="error">{{ error }}</p>
    </div>
    <input
      v-if="!textArea"
      :class="{ error: error }"
      v-bind="$attrs"
      :modelValue="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <textarea
      v-else
      :class="{ error: error }"
      v-bind="$attrs"
      :modelValue="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const props = {
  label: {
    type: String,
    required: true
  },
  modelValue: {
    type: String,
    required: false,
    default: ""
  },
  error: {
    type: String,
    required: false,
    default: ""
  },
  textArea: {
    type: Boolean,
    default: false
  }
};

export default defineComponent({
  props: props
});
</script>

<style lang="scss" scoped>
@import "@/global";

.input {
  position: relative;

  &__row {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  &__error {
    position: absolute;
    top: 0;
    right: 17px;
    font-size: 12px;
    // margin-right: 10%;
    color: $primary1;
  }

  label {
    display: block;
    margin-left: 17px;
    margin-bottom: 3px;
  }

  input {
    font-size: 12px;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    padding: 11px 17px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;

    &:focus {
      border: 1px $primary2 solid;
      outline: none;
    }
    &.error {
      border: 1px $primary1 solid;
    }
  }

  textarea {
    font-size: 12px;
    border-radius: 20px;
    height: 120px;
    width: 100%;
    padding: 11px 17px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    resize: none;

    &::placeholder {
      font-size: 12px;
    }

    &:focus {
      border: 1px $primary2 solid;
      outline: none;
    }
    &.error {
      border: 1px $primary1 solid;
    }
  }
}
</style>
