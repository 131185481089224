<template>
  <div class="route">
    <BaseAlinea>
      <template #header>Route</template>

      <p><span>Praktijkadres</span></p>

      <address>
        Lindendreef 5<br />
        3137 CJ Vlaardingen
      </address>

      <div class="btn">
        <BaseButton @click="onClick()">Open route</BaseButton>
      </div>
    </BaseAlinea>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseAlinea from "../base/BaseAlinea.vue";
import BaseButton from "../base/BaseButton.vue";

export default defineComponent({
  components: { BaseButton, BaseAlinea },
  setup() {
    const onClick = () => {
      const url =
        "https://www.google.com/maps/search/?api=1&query=lindendreef+5+3137CJ+Vlaardingen+NL";
      window.open(url);
    };

    return { onClick };
  }
});
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  margin-bottom: 8px;
}

.btn {
  margin-top: 20px;
  text-align: center;
}
</style>
