
import { defineComponent, reactive, ref, toRefs } from "vue";
import BaseInput from "../base/BaseInput.vue";
import { FormContent } from "@/@types/FormContent";
import { FormErrors } from "@/@types/FormErrors";
import BaseSpinner from "../base/BaseSpinner.vue";
import axios from "axios";

const initialFormContent = {
  name: "",
  email: "",
  body: ""
};

export default defineComponent({
  components: { BaseInput, BaseSpinner },
  setup() {
    const isLoading = ref(false);
    const hasSubmitError = ref(false);
    const isSubmitted = ref(false);

    const formContent = reactive<FormContent>(initialFormContent);

    const formErrors = reactive<FormErrors>({
      nameError: "",
      emailError: "",
      bodyError: ""
    });

    const isValid = () => {
      let isValid = true;
      const { name, email, body } = formContent;

      if (name.length <= 0) {
        isValid = false;
        formErrors.nameError = "Dit veld is verplicht";
      }

      if (email.length <= 0) {
        isValid = false;
        formErrors.emailError = "Dit veld is verplicht";
      }

      if (body.length <= 0) {
        isValid = false;
        formErrors.bodyError = "Dit veld is verplicht";
      }

      return isValid;
    };

    const onSubmit = async () => {
      isLoading.value = true;
      isSubmitted.value = false;
      hasSubmitError.value = false;
      formErrors.bodyError = "";
      formErrors.emailError = "";
      formErrors.nameError = "";

      if (!isValid()) {
        isLoading.value = false;
        return;
      }

      try {
        await axios.post("/.netlify/functions/sendmail", formContent);
        isSubmitted.value = true;

        formContent.name = "";
        formContent.email = "";
        formContent.body = "";
      } catch {
        hasSubmitError.value = true;
      }

      isLoading.value = false;
    };

    return {
      onSubmit,
      ...toRefs(formContent),
      ...toRefs(formErrors),
      isLoading,
      hasSubmitError,
      isSubmitted
    };
  }
});
